// import PerfectScrollbar from 'perfect-scrollbar';
import axios from 'axios';

// const ps = new PerfectScrollbar('.content');

let theEditor = null;
const pageName = document.querySelector("body").dataset.pageName;

const editBtn = document.querySelector('#editBtn');
const saveBtn = document.querySelector('#saveBtn');
const cancelBtn = document.querySelector('#cancelBtn');

if(editBtn) {
    function toggleEditMode(state = true) {
        if(state) {
            editBtn.classList.add('d-none');
            saveBtn.classList.remove('d-none');
            cancelBtn.classList.remove('d-none');
        } else {
            editBtn.classList.remove('d-none');
            saveBtn.classList.add('d-none');
            cancelBtn.classList.add('d-none');
        }
    }

    editBtn.addEventListener('click', (e) => {
        toggleEditMode();

        tinymce.init({
            selector: '.content',
            plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
            imagetools_cors_hosts: ['picsum.photos'],
            menubar: 'file edit view insert format tools table help',
            toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
            toolbar_sticky: true,
            autosave_ask_before_unload: true,
            autosave_interval: '30s',
            autosave_prefix: '{path}{query}-{id}-',
            autosave_restore_when_empty: false,
            autosave_retention: '2m',
            image_advtab: true,
            link_list: [
                { title: 'My page 1', value: 'https://www.tiny.cloud' },
                { title: 'My page 2', value: 'http://www.moxiecode.com' }
            ],
            image_list: [
                { title: 'My page 1', value: 'https://www.tiny.cloud' },
                { title: 'My page 2', value: 'http://www.moxiecode.com' }
            ],
            image_class_list: [
                { title: 'None', value: '' },
                { title: 'Some class', value: 'class-name' }
            ],
            importcss_append: true,
            file_picker_callback: function (callback, value, meta) {
                /* Provide file and text for the link dialog */
                if (meta.filetype === 'file') {
                    callback('https://www.google.com/logos/google.jpg', { text: 'My text' });
                }

                /* Provide image and alt text for the image dialog */
                if (meta.filetype === 'image') {
                    callback('https://www.google.com/logos/google.jpg', { alt: 'My alt text' });
                }

                /* Provide alternative source and posted for the media dialog */
                if (meta.filetype === 'media') {
                    callback('movie.mp4', { source2: 'alt.ogg', poster: 'https://www.google.com/logos/google.jpg' });
                }
            },
            templates: [
                { title: 'New Table', description: 'creates a new table', content: '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>' },
                { title: 'Starting my story', description: 'A cure for writers block', content: 'Once upon a time...' },
                { title: 'New list with dates', description: 'New List with dates', content: '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>' }
            ],
            template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
            template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
            height: 600,
            image_caption: true,
            quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
            noneditable_noneditable_class: 'mceNonEditable',
            toolbar_mode: 'sliding',
            contextmenu: 'link image imagetools table',
            skin: 'oxide',
            content_css: 'default',
            content_style: 'body { font-family:\'Jost\',sans-serif; font-size:16px }'
        });
    });

    saveBtn.addEventListener('click', (e) => {

        var myContent = tinymce.activeEditor.getContent();
        axios.post(route('savePage'), {
            name: pageName,
            content: myContent
        }).then( res => {
            toggleEditMode(false);
            tinymce.activeEditor.destroy();
        })
    })

    cancelBtn.addEventListener('click', (e) => {
        axios.post(route('getContent'), {
            name: pageName
        }).then( res => {
            tinymce.activeEditor.setContent(res.data);
            toggleEditMode(false);
            tinymce.activeEditor.destroy();
        })
    })
}

// Toggle menu mobile
const toggleMenuButton = document.querySelector('.toggle-menu');
toggleMenuButton.addEventListener('click', toggleMenu)

function toggleMenu(e) {
    document.body.classList.toggle('open-nav')
}

// Ferme le menu lorsque l'on clique à l'exterieur
// https://stackoverflow.com/a/153047/4298050
window.addEventListener('click', (e) => {
    // Close menu if visible
    document.body.classList.remove('open-nav')
})
const navigationContainer = document.querySelector('#navigation');
navigationContainer.addEventListener('click', e => e.stopPropagation());


// Page de contact
// const form = document.getElementById('contactForm');
// form.addEventListener('submit', function(e) {
//     e.preventDefault();
//     const email = form.querySelector('#email').value;
//     const sujet = form.querySelector('#sujet').value;
//     const message = form.querySelector('#message').value;
//
//     console.log('envoi du formulaire', email.isEmail(), sujet, message)
//     axios.post(route('sendMessage'), {
//         email, sujet, message
//     }).then( res => {
//         toggleEditMode(false);
//         tinymce.activeEditor.destroy();
//     })
// });

// Page de contact
// Fonction pour générer l'e-mail
function generateEmail() {
	const username = 'lafermesansnom';
	const service = 'gmail';
	const domain = 'com'

	// Fonction pour mettre à jour le lien mailto et simuler un clic
	function updateMailtoAndClick() {
		const mailtoLink = 'mailto:'+username+'@'+service+'.'+domain;
		const contactLink = document.getElementById("button-contact");
		contactLink.setAttribute("href", mailtoLink); // Met à jour le lien mailto de l'élément <a>
		contactLink.click(); // Simule un clic sur le lien
	}

	document.getElementById("button-contact").addEventListener("click", updateMailtoAndClick);
}

// Appeler la fonction generateEmail() au chargement de la page
window.onload = generateEmail;
